import { mapGetters } from 'vuex'

const moduleName = 'quotes'

export default {
  computed: {
    ...mapGetters(moduleName, {
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    })
  },
  methods: {}
}
