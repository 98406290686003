<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>{{ title }}</span>
      </div>
    </header>
    <section class="modal-card-body">
      <image-select v-if="value && imageSelectPage"
        v-show="imageSelectPage.show"
        v-model="selectedImageIds"
        :quote-id="value.quoteId"
        :pre-select="'NotSent'" />
      <div v-if="value && SubmitPage">
        <div v-if="modalType === 'SendInvoice'">
          <div class="field">
            <label class="label">Select an invoice to send:</label>
            <ul class="control">
              <li v-for="invoice in value.invoices"
                :key="invoice.invoiceId"
                class="mt-2 pretty p-default p-round p-smooth">
                <input id="chk-pretty-excess-type"
                  name="chk-pretty-excess-type"
                  :value="invoice.invoiceId"
                  v-model="selectedInvoice"
                  type="radio">
                <div class="state p-primary">
                  <label>{{ invoice.invoiceNo }}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-else-if="modalType === 'SendQuoteWithConfirm'">
          <div class="field">
            <p class="has-text-primary has-text-weight-bold">Confirm Submission</p>
            <p>You are about to submit items to EstImage. Once items have been submitted changes can only be made by creating a supplementary.</p>
            <p>Please review all details carefully to ensure accuracy before proceeding.</p>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button v-show="activeComponent > 0"
        class="button is-success tooltip"
        @click="previous()"
        data-tooltip="Previous">
        <span class="icon is-small">
          <i class="mdi mdi-24px mdi-chevron-left" />
        </span>
      </button>
      <button v-show="activeComponent < componentStatuses.length - 1"
        class="button is-success tooltip"
        @click="next()"
        data-tooltip="Next">
        <span class="icon is-small">
          <i class="mdi mdi-24px mdi-chevron-right" />
        </span>
      </button>
      <button v-show="activeComponent === componentStatuses.length - 1"
        class="button is-success tooltip"
        @click="submit()"
        data-tooltip="Submit"
        :disabled="isSubmitDisabled">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-send" />
        </span>
        <span>Submit</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { QuoteNotificationMixin } from '../mixins'
import ImageSelect from '@/components/ImageSelect'
import { EstImageSendQuoteModel } from '@/classes'

export default {
  name: 'EstImageSendQuoteModal',
  components: {
    BaseModalEx,
    ImageSelect
  },
  mixins: [QuoteNotificationMixin],
  props: {
    value: null,
    modalType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      sendQuoteObject: null,
      selectedImageIds: [],
      componentStatuses: [],
      activeComponent: 0,
      selectedInvoice: null
    }
  },
  validations: {},
  computed: {
    isSubmitDisabled() {
      if (this.modalType === 'ImageSelect') {
        return this.selectedImageIds.length < 1
      }
      return false
    },
    imageSelectPage() {
      return this.componentStatuses.find((c) => c.name === 'ImageSelectPage')
    },
    SubmitPage() {
      return this.componentStatuses.find((c) => c.name === 'SubmitPage')
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    selectedImageIds: {
      handler: function (newVal, oldVal) {
        this.sendQuoteObject.imageIds = this.selectedImageIds.map((i) => i.quoteImageId)
      },
      deep: true
    }
  },
  created() {
    this.sendQuoteObject = new EstImageSendQuoteModel()
    this.sendQuoteObject.quoteId = this.value.quoteId
    if (this.modalType === 'ImageSelect') {
      this.componentStatuses.push({ name: 'ImageSelectPage', show: true })
    } else if (this.modalType === 'SendQuote') {
      this.componentStatuses.push({ name: 'ImageSelectPage', show: true })
    } else if (this.modalType === 'SendQuoteWithConfirm') {
      this.componentStatuses.push({ name: 'ImageSelectPage', show: true })
      this.componentStatuses.push({ name: 'SubmitPage', show: true })
    } else if (this.modalType === 'UpdateVehicle') {
      this.componentStatuses.push({ name: 'SubmitPage', show: true })
    } else if (this.modalType === 'SendInvoice') {
      this.componentStatuses.push({ name: 'SubmitPage', show: true })
      this.selectedInvoice = this.value.invoices[0]?.invoiceId
    }
  },
  beforeMount() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    previous() {
      if (this.activeComponent > 0) {
        this.activeComponent--
        this.componentStatuses[this.activeComponent + 1].show = false
        this.componentStatuses[this.activeComponent].show = true
      }
    },
    next() {
      if (this.activeComponent < this.componentStatuses.length - 1) {
        this.activeComponent++
        this.componentStatuses[this.activeComponent - 1].show = false
        this.componentStatuses[this.activeComponent].show = true
      }
    },
    cancel() {
      this.componentStatuses = []
      this.$emit('close')
    },
    submit() {
      this.componentStatuses = []
      this.sendQuoteObject.invoiceId = this.selectedInvoice
      this.$emit('submit', this.sendQuoteObject)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
